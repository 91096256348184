import gs1302 from "../images/discover/1302@2x.png";
import antiek from "../images/discover/antiek@2x.png";
import b15 from "../images/discover/b15@2x.png";
import fullMoon from "../images/discover/full-moon@2x.png";
import hopman from "../images/discover/hopman@2x.png";
import jdmBlond from "../images/discover/jus-de-mer-blond@2x.png";
import jdmDubbel from "../images/discover/jus-de-mer-dubbel@2x.png";
import maitreBlond from "../images/discover/maitre-blond@2x.png";
import noirDottignies from "../images/discover/noir-de-dottignies@2x.png";
import pereNoel from "../images/discover/pere_noel@2x.png";
import realLove from "../images/discover/real-love@2x.png";
import vleterenAlt from "../images/discover/vleteren_alt@2x.png";

export const leftBottles = [
    [
        jdmBlond,
        {
            alc: "7,2 vol %",
            brewery: "Brouwerij Jus de mer",
            ibu: "62",
            name: "Jus de mer - Blond",
            text:
                "Een blond bier met subtiel fruitige smaak, licht gekruid, een aangename bitterheid en evenwichtige afdronk. Bier van hoge gisting met nagisting op fles, ongefilterd en ongepasteuriseerd. Grondstoffen: water, gerstemout, maïs, hop, kruiden en gist.",
        },
    ],
    [
        jdmDubbel,
        {
            alc: "8,8 vol %",
            brewery: "Brouwerij Jus de mer",
            ibu: "12",
            name: "Jus de mer - Dubbel 8",
            text:
                "Een donker degustatiebier met kruidig aroma, volmondige smaak, fruitige toetsen en een lange afdronk. Bier van hoge gisting met 6 verschillende moutsoorten, ongefilterd en ongepasteuriseerd. Grondstoffen: water, gerstemout, tarwemout, hop, suiker, kruiden en gist.",
        },
    ],
    [
        fullMoon,
        {
            alc: "10,2 vol %",
            brewery: "Brouwerij Triporteur",
            ibu: "20",
            name: "Full Moon 12",
            text:
                "Een quadrupel bier met eik gekenmerkt door een fruitige smaak en toetsen van caramel. Bier van hoge gisting met nagisting op fles. Grondstoffen: water, mout, 2 soorten hop, 3 soorten suiker.",
        },
    ],
    [
        realLove,
        {
            alc: "7,0 vol %",
            brewery: "Brouwerij B",
            ibu: "53",
            name: "Real Love",
            text:
                "Een sterk blond bier met ruitige en kruidige toetsen in neus en smaak met een pittige bitterheid die ook terug komt in de afdronk. Grondstoffen: water, mout, hop en gist.",
        },
    ],
    [
        b15,
        {
            alc: "7,5 vol %",
            brewery: "Brouwerij B",
            ibu: "27",
            name: "B15",
            text:
                "Een Brut bier, fris en fruitig van geur met een subtiele finesse die aan wijn doet denken. Volmondig van smaak met een zachte zoetbittere afdronk. Grondstoffen: water, mout, hop en gist.",
        },
    ],
    [
        noirDottignies,
        {
            alc: "8,5 vol %",
            brewery: "Brouwerij De Ranke",
            ibu: "46",
            name: "Noir De Dottignies",
            text:
                "Een complex, donker bier met stevig gebrande moutsmaak en een zachte hopbitterheid. Bier van hoge gisting, ongefilterd en ongepasteuriseerd. Grondstoffen: water, 7 verschillende gerstemouten, suiker, hop en gist.",
        },
    ],
];

export const rightBottles = [
    [
        pereNoel,
        {
            alc: "7,0 vol %",
            brewery: "Brouwerij De Ranke",
            ibu: "50",
            name: "Pere Noël",
            text:
                "Een bitter kerstbier met toevoeging van zoethout en een bittere, lichtkruidige smaak met lange afdronk. Bier van hoge gisting, ongefilterd en ongepasteuriseerd. Grondstoffen: water, gerstemout, suiker, zoethout, hop en gist.",
        },
    ],
    [
        hopman,
        {
            alc: "4,5 vol %",
            brewery: "Brouwerij Bram Brass",
            ibu: "30",
            name: "Hopman",
            text:
                "Een India Style Lager bier gehopt als een iPA, vlot drinkbaar. Grondstoffen: water, gerstemout, roggemout, hop en gist.",
        },
    ],
    [
        maitreBlond,
        {
            alc: "6,2 vol %",
            brewery: "Brouwerij De Meester",
            name: "Maître Blond",
            text:
                "Een blond volmoutig bier vol karakter en pit, een uitgesproken dorstlesser. Grondstoffen: water, gerstemout, hop en gist.",
        },
    ],
    [
        gs1302,
        {
            alc: "5,2 vol %",
            brewery: "Brouwerij De Feniks",
            name: "1302",
            text:
                "Een artisanaal gebrouwen Lager (Pils). Grondstoffen: water, pilsmout, hop en gist.",
        },
    ],
    [
        antiek,
        {
            alc: "8,0 vol %",
            brewery: "Brouwerij Deca",
            ibu: "25",
            name: "Antiek",
            text:
                "Blond/amber 'Old Strong Ale'. Bier van hoge gisting met nagisting op fles, ongefilterd en ongepasteuriseerd. Grondstoffen: water, gerstemout, basterdsuiker, hop, kruiden en gist.",
        },
    ],
    [
        vleterenAlt,
        {
            alc: "8,0 vol %",
            brewery: "Brouwerij Deca",
            ibu: "25",
            name: "Antiek",
            text:
                "Een helder roodbruin bier met een kruidig aroma van koekjes en peperkoek en karamel, maar ook enigszins fruitig. Bier van hoge gisting met nagisting op fles, ongefilterd en ongepasteuriseerd. Grondstoffen: water, gerstemout, basterdsuiker, hop, kruiden en gist.",
        },
    ],
];
