// @flow
/* eslint-disable simple-import-sort/imports */

import React from "react";

import style from "./style.module.scss";

import Page from "../containers/Page";
import Discover from "../components/Discover";
import LinkButton from "../components/LinkButton";

import { leftBottles, rightBottles } from "../discover/b33rbox";
import {
    leftBottles as leftBottlesWinter,
    rightBottles as rightBottlesWinter,
} from "../discover/winter";

const IndexPage = () => {
    return (
        <Page>
            <div className={style.intro}>
                <p>
                    B33RBOX.be levert bierpakketten met hoogwaardige bieren
                    gebrouwen met liefde en vakmanschap door lokale,
                    kleinschalige brouwerijen. Deze bierpakketten zijn door ons
                    samengesteld met zorg en evenwicht qua bierstijlen en
                    -smaken.
                </p>
                <p>
                    Bij elk pakket ontdekt u ook het verhaal achter de brouwerij
                    en krijgt u bovendien een originele attentie.
                </p>
                <p>
                    Naast <strong>The OriginalBox</strong> bieden wij nu ook{" "}
                    <strong>The WinterBox</strong> aan.
                </p>
                <p>Ontdek welke bieren in de pakketten zitten.</p>
            </div>
            <h1>The WinterBox</h1>
            <Discover
                leftBottles={leftBottlesWinter}
                rightBottles={rightBottlesWinter}
            />
            <h1>The OriginalBox</h1>
            <Discover leftBottles={leftBottles} rightBottles={rightBottles} />
            <div className={style.buttonWrapper}>
                <LinkButton
                    size={"xl"}
                    url={"https://m.me/B33RBOX.be"}
                    target={"_blank"}
                >
                    Ik neem er één!
                </LinkButton>
                <div>
                    <small>Of meer ;)</small>
                </div>
            </div>
        </Page>
    );
};

export default IndexPage;
