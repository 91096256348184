// @flow

import classnames from "classnames";
import React, { useState } from "react";

import box from "../../images/discover/box@2x.png";
import close from "../../images/discover/close.svg";
import Bottle from "./Bottle";
import style from "./style.module.scss";

type Props = {
    leftBottles: Array,
    rightBottles: Array,
};

const Discover = ({ leftBottles, rightBottles }: Props) => {
    const [activeBottle, setActiveBottle] = useState(null);

    const handleBottleClick = (bottle: Array) => {
        setActiveBottle(
            activeBottle && activeBottle[0] === bottle[0] ? null : bottle,
        );
    };

    return (
        <>
            <div className={style.wrapper}>
                <div className={style.scrollContainer}>
                    <div className={style.inner}>
                        <div className={style.bottles}>
                            {leftBottles.map((bottle, index) => (
                                <Bottle
                                    key={index}
                                    active={
                                        activeBottle
                                            ? activeBottle[0] === bottle[0]
                                            : null
                                    }
                                    alt={bottle[1].name}
                                    image={bottle[0]}
                                    onClick={() => handleBottleClick(bottle)}
                                />
                            ))}
                        </div>
                        <img
                            className={style.box}
                            src={box}
                            alt={"De box"}
                            width={215}
                        />
                        <div className={style.bottles}>
                            {rightBottles.map((bottle, index) => (
                                <Bottle
                                    key={index}
                                    active={
                                        activeBottle
                                            ? activeBottle[0] === bottle[0]
                                            : null
                                    }
                                    alt={bottle[1].name}
                                    image={bottle[0]}
                                    onClick={() => handleBottleClick(bottle)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className={style.stripe} />
            </div>
            <div className={classnames(style.info, activeBottle && style.show)}>
                {activeBottle && (
                    <>
                        <p className={style.title}>
                            <span>
                                {activeBottle[1].name} |{" "}
                                {activeBottle[1].brewery}
                            </span>
                            <span>
                                {activeBottle[1].alc}
                                {activeBottle[1].ibu &&
                                    " / IBU " + activeBottle[1].ibu}
                                {activeBottle[1].serve &&
                                    " / " + activeBottle[1].serve}
                            </span>
                        </p>
                        <p className={style.text}>{activeBottle[1].text}</p>
                        <button
                            type={"button"}
                            onClick={() => setActiveBottle(null)}
                        >
                            <img src={close} width={14} alt={"Sluiten"} />
                        </button>
                    </>
                )}
            </div>
        </>
    );
};

export default Discover;
