import bebeDamour from "../images/discover/bebe-damour@2x.png";
import dePoes from "../images/discover/de-poes@2x.png";
import francBelge from "../images/discover/franc-belge@2x.png";
import frutha from "../images/discover/frutha@2x.png";
import guldenberg from "../images/discover/guldenberg@2x.png";
import lamourToujours from "../images/discover/lamour-toujours@2x.png";
import maitreQuad from "../images/discover/maitre-quad@2x.png";
import maitreTripel from "../images/discover/maitre-tripel@2x.png";
import meulebeeksWit from "../images/discover/meulebeeks-wit@2x.png";
import tieltsKlooster from "../images/discover/tielts-klooster-bier@2x.png";
import triporteurHeaven from "../images/discover/triporteur-from-heaven@2x.png";
import triporteurNipple from "../images/discover/triporteur-nipple@2x.png";

export const leftBottles = [
    [
        lamourToujours,
        {
            alc: "7,4 vol %",
            brewery: "Brouwerij BramBrass",
            ibu: "62",
            name: "L'amour Toujours",
            serve: "6-8°C",
            text:
                "Een India Pale Ale, een mix van tropisch fruitige hoptoetsen en biscuittoetsen van mout. Ongefilterd met hergisting op fles. Grondstoffen: water, gerstemout, havermout, gist en hop (Amarillo, Ekuanot, Simcoe en Mosaic als aromahop op het einde van de kook, geen bitterhop).",
        },
    ],
    [
        bebeDamour,
        {
            alc: "4,9 vol %",
            brewery: "Brouwerij BramBrass",
            ibu: "50",
            name: "Bébé D'amour",
            serve: "6-8°C",
            text:
                "Een vlot doordrinkbare en frisse session IPA met fruitige en grassige toetsen. Ongefilterd met hergisting op fles. Grondstoffen: water, gerstemout, speltmout, gist en hop (Northern Brewer als bitterhop en Zythos als aromahop op het einde van de kook en de dry-hopping).",
        },
    ],
    [
        francBelge,
        {
            alc: "5,2 vol %",
            brewery: "Brouwerij De Ranke",
            ibu: "40",
            name: "Franc Belge",
            serve: null,
            text:
                "Een amberkleuring bier met een zachte moutsmaak met een aangename bittere toets op het einde. Ongefilterd en ongepasteuriseerd met hergisting op fles. Grondstoffen: water, gerstemout, hop (Fuggles), gist.",
        },
    ],
    [
        guldenberg,
        {
            alc: "8,0 vol %",
            brewery: "Brouwerij De Ranke",
            ibu: "40",
            name: "Guldenberg",
            serve: null,
            text:
                "Volmondig goudblond abdijbier die perfect balanceert tussen zoet en bitter. Ongefilterd en ongepasteuriseerd met hergisting op fles. Grondstoffen: water, gerstemout, hop (Hallertau Mittelfruh), gist.",
        },
    ],
    [
        tieltsKlooster,
        {
            alc: "8,0 vol %",
            brewery: "Brouwerij De Poes",
            ibu: "25",
            name: "Tielts Kloosterbier",
            serve: "8-10°C",
            text:
                "Een typisch Belgische dubbel met caramel en zacht geroosterde tarwe, met toegevoegde kruiden voor een extra touch in de afdronk. Ongefilterd bier van hoge gisting met nagisting op fles. Grondstoffen: gerst, water, tarwe, kandijsuiker, hop (bitterhop Merkur & aromahop Whitbread Golding), gist en kruiden.",
        },
    ],
    [
        dePoes,
        {
            alc: "4,8 vol %",
            brewery: "Brouwerij De Poes",
            ibu: "25",
            name: "De Poes",
            serve: "3°C",
            text:
                "Een blond bier van lage gisting met een lichte gerstsmaak en een typisch hoparoma. Ongefilterd met nagisting op fles, uitzonderlijk voor een Dortmunder Type Pils. Grondstoffen: gerst, water, suiker, 2 Belgische hopsoorten (waaronder SAAZ), gist.",
        },
    ],
];

export const rightBottles = [
    [
        maitreTripel,
        {
            alc: "9,5 vol %",
            brewery: "Brouwerij De Meester",
            ibu: "24",
            name: "Maître Tripel",
            serve: "6-8°C",
            text:
                "Een bronskleurig bier met een uitgesproken smaakpallet en complexe aroma’s. Ongefilterd met hergisting op fles. Grondstoffen: water, 3 soorten mout, kandijsuiker, 2 soorten hop, gist.",
        },
    ],
    [
        maitreQuad,
        {
            alc: "9,5 vol %",
            brewery: "Brouwerij De Meester",
            ibu: "18",
            name: "Maître Quad",
            serve: "6-8°C",
            text:
                "Een bijna zwart bier met een volmondige smaak, moutbitter en zoete tinten van o.a. chocolade. Ongefilterd met hergisting op fles. Grondstoffen: water, 2 soorten hoofdmout en 3 soorten caramout, donkere kandijsuiker, 2 soorten hop, gist.",
        },
    ],
    [
        frutha,
        {
            alc: "6,5 vol %",
            brewery: "Brouwerij Klondiker",
            ibu: "15,8",
            name: "Frutha",
            serve: null,
            text:
                "Een helderrood kriekbier met subtiel fruitige smaak en lichtzure afdronk. Hergisting op fles. Grondstoffen: water, 2 soorten mout, kruiden, suiker, gist.",
        },
    ],
    [
        meulebeeksWit,
        {
            alc: "4,8 vol %",
            brewery: "Brouwerij Klondiker",
            ibu: "16,5",
            name: "Meulebeeks Wit",
            serve: null,
            text:
                "Een witbier met strogele kleur, moutbitter mondgevoel door gebruik van koriander en sinaasappelzeste. Hergisting op fles. Grondstoffen: water, 2 soorten mout, kruiden, suiker, gist.",
        },
    ],
    [
        triporteurNipple,
        {
            alc: "8,5 vol %",
            brewery: "Brouwerij Triportuer",
            ibu: "35",
            name: "Triporteur Nipple",
            serve: "8-12°C",
            text:
                "Een Belgische versie van een tripel, sexy zoetig met natuurlijke aroma’s. Bier van hoge gisting met hergisting op fles. Grondstoffen: water, karamelmout, mix van Belgische hop, BOM-gist.",
        },
    ],
    [
        triporteurHeaven,
        {
            alc: "6,2 vol %",
            brewery: "Brouwerij Triportuer",
            ibu: "28",
            name: "Triporteur From Heaven",
            serve: "5-8°C",
            text:
                "Een hemels hoppig bier, de moderne versie van een Belgische ale. Bier van hoge gisting met hergisting op fles. Grondstoffen: water, huisgebakken BOM-mout, mix van Europese en Amerikaanse hop, Belgische donkere suiker en witte kandijsuiker, BOM-gist.",
        },
    ],
];
