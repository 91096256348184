// @flow

import classnames from "classnames";
import React from "react";

import style from "./style.module.scss";

type Props = {
    active: boolean | null,
    alt: string,
    image: string,
    onClick: () => void,
};

const Bottle = ({ image, alt, onClick, active }: Props) => (
    <button
        className={classnames(
            style.bottle,
            active === null ? style.normal : active ? style.active : null,
        )}
        onClick={onClick}
        type={"button"}
    >
        <img src={image} alt={alt} width={50} />
    </button>
);

export default Bottle;
