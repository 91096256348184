// @flow

import React from "react";
import { Helmet } from "react-helmet";

import logo from "../../images/logo.svg";
import style from "./style.module.scss";

type Props = {
    children: any,
};

const Page = ({ children }: Props) => (
    <div className={style.wrapper}>
        <Helmet>
            <meta charSet="utf-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <title>B33RBOX.be</title>
            <meta
                name={"description"}
                content={
                    "B33RBOX.be levert bierpakketten met hoogwaardige bieren gebrouwen met liefde en vakmanschap door lokale, kleinschalige brouwerijen"
                }
            />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
        </Helmet>
        <header>
            <img src={logo} alt={"B33RBOX.be"} width={150} />
        </header>
        {children}
    </div>
);

export default Page;
