// @flow

import classnames from "classnames";
import React from "react";

import style from "./style.module.scss";

type Props = {
    url: string,
    children: *,
    size?: "sm" | "xl",
    target?: "_self" | "_blank",
};

const LinkButton = ({ url, children, size, target = "_self" }: Props) => (
    <a
        href={url}
        className={classnames(style.linkButton, size && style[size])}
        target={target}
    >
        {children}
    </a>
);

export default LinkButton;
